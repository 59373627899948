import React from "react"
import Layout from "../../components/Layout"
import { graphql, Link } from "gatsby"
import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

export const query = graphql`
  query ($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      thumbnail {
        title
        file {
          url
        }
      }
      publishedDate(fromNow: true)
      embed {
        embed
      }
      embedVideoLink
      body {
        raw
        references {
          contentful_id
          title
          file {
            url
          }
        }
      }
    }
  }
`

const Blog = props => {
  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: node => {
        const asset = assetMap.get(node.data.target.sys.id)
        return <img className="mb24" src={asset.file.url} alt={asset.title} />
      },
    },
  }

  const {
    data: { contentfulBlogPost },
  } = props

  const assetMap = new Map()
  for (const asset of contentfulBlogPost.body.references) {
    assetMap.set(asset.contentful_id, asset)
  }

  const convertLinkToIframe = link => {
    const embedLink = link.split("/")[3]
    return (
      <iframe
        className="embed-responsive-item"
        width="1244"
        height="527"
        src={`https://www.youtube.com/embed/${embedLink}`}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    )
  }
  return (
    <Layout>
      <div className="main-container">
        <section>
          <div className="container">
            <div className="row">
              <div className="col-sm-10 col-sm-offset-1">
                <div className="post-snippet mb64">
                  {contentfulBlogPost && contentfulBlogPost.embed ? (
                    <>
                      {contentfulBlogPost.embedVideoLink ? (
                        convertLinkToIframe(contentfulBlogPost.embedVideoLink)
                      ) : (
                        <div
                          className="embed-video-container embed-responsive embed-responsive-16by9"
                          dangerouslySetInnerHTML={{
                            __html: contentfulBlogPost.embed.embed,
                          }}
                        ></div>
                      )}
                    </>
                  ) : (
                    contentfulBlogPost.thumbnail && (
                      <img
                        className="mb24"
                        alt={contentfulBlogPost.title}
                        src={contentfulBlogPost.thumbnail.file.url}
                      />
                    )
                  )}

                  <div className="post-title">
                    <span className="label">
                      {contentfulBlogPost.publishedDate}
                    </span>
                    <h4 className="inline-block">{contentfulBlogPost.title}</h4>
                  </div>
                  <ul className="post-meta">
                    <li>
                      <i className="ti-user"></i>
                      <span>
                        Written by
                        <Link to="/about "> Tayo Oladele </Link>
                      </span>
                    </li>
                    {contentfulBlogPost.category && (
                      <li>
                        <i className="ti-tag"></i>

                        <span>
                          Tagged as
                          <Link to="/category">
                            {contentfulBlogPost.category}
                          </Link>
                        </span>
                      </li>
                    )}
                  </ul>
                  <hr />

                  <div className="lead">
                    {documentToReactComponents(
                      JSON.parse(contentfulBlogPost.body.raw),
                      options
                    )}
                  </div>
                </div>
                <hr />
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default Blog
